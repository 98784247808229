var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',{staticClass:"dense-inputs"},[_c('v-row',{staticClass:"pt-0 pl-0 justify-space-between",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-row align-center",attrs:{"cols":"4","md":"4"}},[_c('v-text-field',{staticClass:"mt-2 mb-0",attrs:{"label":_vm.$t('message.filterResults'),"value":_vm.searchTerm,"autocomplete":"off","hide-details":"auto","prepend-inner-icon":"mdi-filter-outline"},on:{"change":function($event){_vm.searchTerm = $event}}}),_c('v-btn',{staticClass:"ml-3",attrs:{"loading":_vm.loading.filterResults},on:{"click":function($event){return _vm.filterResults()}}},[_vm._v(_vm._s(_vm.$t('message.filter')))]),_c('v-btn',{staticClass:"ml-3",on:{"click":function($event){_vm.searchTerm = null}}},[_c('v-icon',[_vm._v("refresh")])],1)],1),_c('div',{staticClass:"d-flex flex-row col-md-8 col-lg-6 no-gutters align-center"},[_c('v-col',{attrs:{"lg":"2","md":"2","xs":"3"}},[_c('v-select',{attrs:{"items":_vm.years,"loading":_vm.loading.filter.years,"placeholder":_vm.$t('message.allYears'),"autocomplete":"off","dense":"","hide-details":"auto","item-text":"Year.text","item-value":"Year.id","required":"","solo":""},model:{value:(_vm.filterOptions.year),callback:function ($$v) {_vm.$set(_vm.filterOptions, "year", $$v)},expression:"filterOptions.year"}})],1),_c('v-col',{attrs:{"lg":"3","md":"3","xs":"3"}},[_c('v-select',{staticClass:"ml-2",attrs:{"items":_vm.months,"loading":_vm.loading.filter.months,"placeholder":_vm.$t('message.allMonths'),"autocomplete":"off","clearable":"","dense":"","hide-details":"auto","item-text":"Month.text","item-value":"Month.id","required":"","solo":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Month.code)+" ")]}}]),model:{value:(_vm.filterOptions.month),callback:function ($$v) {_vm.$set(_vm.filterOptions, "month", $$v)},expression:"filterOptions.month"}})],1),_c('v-col',{attrs:{"lg":"3","md":"3","xs":"3"}},[_c('v-select',{staticClass:"ml-2",attrs:{"items":_vm.offices,"loading":_vm.loading.filter.offices,"placeholder":_vm.$t('message.allOffices'),"autocomplete":"off","clearable":"","dense":"","hide-details":"auto","item-value":"Office.id","item-text":"Office.code","solo":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Office.code)+" ")]}}]),model:{value:(_vm.filterOptions.office),callback:function ($$v) {_vm.$set(_vm.filterOptions, "office", $$v)},expression:"filterOptions.office"}})],1),_c('v-col',{staticClass:"col-md-4 col-lg-4 pl-2 d-flex flex-row align-center"},[_c('v-btn',{staticClass:"flex-grow-1",on:{"click":function($event){return _vm.loadGrossMargins()}}},[(_vm.loading.fetch)?_c('span',[_vm._v(_vm._s(_vm.$t('message.fetching')))]):_c('span',[_vm._v(_vm._s(_vm.$t('message.fetch')))])]),_c('v-btn',{staticClass:"ml-3 flex-grow-1",attrs:{"loading":_vm.loading.reset},on:{"click":function($event){return _vm.resetFilters()}}},[_vm._v(_vm._s(_vm.$t('message.reset')))]),_c('ExportTableJson',{staticClass:"ml-3",staticStyle:{"padding-top":"0px !important","margin-bottom":"0px !important"},attrs:{"export-conditions":_vm.exportConditions,"export-data":_vm.GrossMargins,"export-fields":_vm.headers,"export-source":'grossmargins'}})],1)],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading.grossMargins,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-data-table',{staticClass:"mt-0 appic-table-light specification-table",attrs:{"footer-props":{
                itemsPerPageOptions: _vm.itemsPerPageOptions,
                showCurrentPage: true,
                showFirstLastPage: true
            },"headers":_vm.headers,"items":_vm.GrossMargins,"options":_vm.tableOptions,"search":_vm.searchTerm,"calculate-widths":"","dense":"","id":"grossMarginsTable","item-key":"Account.id","sort-by":"GrossMargin.document_date","sort-desc":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.GrossMargin.source",fn:function(ref){
            var item = ref.item;
return [(item.GrossMargin.source == 'invoice')?_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('message.dsSs')))]):(item.GrossMargin.source == 'whsales_invoice')?_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('message.wh')))]):_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$('message.na')))])]}},{key:"item.GrossMargin.document_title",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.GrossMargin.document_title))])]}},{key:"item.GrossMargin.document_date",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.GrossMargin.document_date))+" ")]}},{key:"item.GrossMargin.payment_term",fn:function(ref){
            var item = ref.item;
return [(item.GrossMargin.payment_term != null)?[(item.GrossMargin.payment_term.length < 20)?[_vm._v(_vm._s(item.GrossMargin.payment_term))]:[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"truncate"},'span',attrs,false),on),[_vm._v(_vm._s(item.GrossMargin.payment_term))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.GrossMargin.payment_term))])])]]:_vm._e()]}},{key:"item.GrossMargin.etddate",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.GrossMargin.etddate))+" ")]}},{key:"item.GrossMargin.etadate",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.GrossMargin.etadate))+" ")]}},{key:"item.GrossMargin.due",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.GrossMargin.due))+" ")]}},{key:"item.GrossMargin.received",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.GrossMargin.received))+" ")]}},{key:"item.GrossMargin.overdue",fn:function(ref){
            var item = ref.item;
return [(item.GrossMargin.overdue != null)?_c('span',{class:item.GrossMargin.overdue > 30 ? 'red--text' : 'orange--text'},[_vm._v(_vm._s(item.GrossMargin.overdue + " " + _vm.$t('message.days')))]):_vm._e()]}},{key:"item.GrossMargin.sellingvalue",fn:function(ref){
            var item = ref.item;
return [(item.GrossMargin.sellingvalue != null)?_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.currency(item.GrossMargin.currency_id) + " " + _vm.formatThisNumber(item.GrossMargin.sellingvalue, _vm.findCurrencyPrecision(item.GrossMargin.currency_id) ))+" ")]):_vm._e()]}},{key:"item.GrossMargin.cost",fn:function(ref){
            var item = ref.item;
return [(item.GrossMargin.cost != null)?_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.currency(item.GrossMargin.currency_id) + " " + _vm.formatThisNumber(item.GrossMargin.cost, _vm.findCurrencyPrecision(item.GrossMargin.currency_id) ))+" ")]):_vm._e()]}},{key:"item.GrossMargin.grossmargin",fn:function(ref){
            var item = ref.item;
return [(item.GrossMargin.grossmargin != null)?_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.currency(item.GrossMargin.currency_id) + " " + _vm.formatThisNumber(item.GrossMargin.grossmargin, _vm.findCurrencyPrecision(item.GrossMargin.currency_id) ))+" ")]):_vm._e()]}},{key:"item.GrossMargin.grossmargin_usd",fn:function(ref){
            var item = ref.item;
return [(item.GrossMargin.account_id)?_c('span',{staticClass:"text-left text-no-wrap font-weight-bold"},[_vm._v(_vm._s('USD ' + _vm.formatThisNumber(item.GrossMargin.grossmargin_usd,'0,0.00')))]):_c('span',{staticClass:"text-left ml-3"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"warning--text"},'v-icon',attrs,false),on),[_vm._v("mdi mdi-alert-circle")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.grossMarginNotGenerated')))])])],1)]}},{key:"item.GrossMargin.account_id",fn:function(ref){
            var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.moreActions')))])])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(item.GrossMargin.account_id != null)?_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.updateGrossMargin(item.GrossMargin.account_id)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"black lighten-1","small":""}},[_vm._v("edit")]),_vm._v(_vm._s(_vm.$t('message.updateGrossMargin'))+" ")],1):_vm._e(),(item.GrossMargin.account_id == null && item.GrossMargin.source == 'invoice')?_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.newGrossMargin(item.GrossMargin.source, item.GrossMargin.related_document_id)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"black lighten-1","small":""}},[_vm._v("edit")]),_vm._v(_vm._s(_vm.$t('message.generateGrossMargin'))+" ")],1):_vm._e(),(item.GrossMargin.account_id == null && item.GrossMargin.source == 'whsales_invoice')?_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.newGrossMargin(item.GrossMargin.source, item.GrossMargin.related_document_id)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"black lighten-1","small":""}},[_vm._v("edit")]),_vm._v(_vm._s(_vm.$t('message.generateGrossMargin'))+" ")],1):_vm._e(),(item.GrossMargin.account_id != null)?_c('PrintGrossMarginButton',{attrs:{"gross-margin-id":item.GrossMargin.account_id,"source":item.GrossMargin.source,"update-mode":true,"list-mode":true}}):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
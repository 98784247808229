<template>
    <div>
        <v-sheet class="dense-inputs">
            <v-row no-gutters class="pt-0 pl-0 justify-space-between">
                <v-col cols="4" md="4" class="d-flex flex-row align-center">
                    <v-text-field
                        :label="$t('message.filterResults')"
                        :value="searchTerm"
                        autocomplete="off"
                        hide-details="auto"
                        class="mt-2 mb-0"
                        prepend-inner-icon="mdi-filter-outline"
                        @change="searchTerm = $event"
                    ></v-text-field>
                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults()">{{ $t('message.filter') }}</v-btn>
                    <v-btn class="ml-3" @click="searchTerm = null"><v-icon>refresh</v-icon></v-btn>
                </v-col>
                <div class="d-flex flex-row col-md-8 col-lg-6 no-gutters align-center">
                    <v-col lg="2" md="2" xs="3">
                        <v-select
                            :items="years"
                            :loading="loading.filter.years"
                            :placeholder="$t('message.allYears')"
                            autocomplete="off"
                            dense
                            hide-details="auto"
                            item-text="Year.text"
                            item-value="Year.id"
                            required
                            solo
                            v-model="filterOptions.year"
                        />
                    </v-col>
                    <v-col lg="3" md="3" xs="3">
                        <v-select
                            :items="months"
                            :loading="loading.filter.months"
                            :placeholder="$t('message.allMonths')"
                            autocomplete="off"
                            clearable
                            class="ml-2"
                            dense
                            hide-details="auto"
                            item-text="Month.text"
                            item-value="Month.id"
                            required
                            solo
                            v-model="filterOptions.month"
                        >
                            <template v-slot:selection="{item}">
                                {{ item.Month.code }}
                            </template>
                        </v-select>
                    </v-col>
                    <v-col lg="3" md="3" xs="3">
                        <v-select
                            :items="offices"
                            :loading="loading.filter.offices"
                            :placeholder="$t('message.allOffices')"
                            autocomplete="off"
                            clearable
                            class="ml-2"
                            dense
                            hide-details="auto"
                            item-value="Office.id"
                            item-text="Office.code"
                            solo
                            v-model="filterOptions.office"
                        >
                            <template v-slot:selection="{item}">
                                {{ item.Office.code }}
                            </template>
                        </v-select>
                    </v-col>
                    <v-col class="col-md-4 col-lg-4 pl-2 d-flex flex-row align-center">
                        <v-btn
                            @click="loadGrossMargins()"
                            class="flex-grow-1"
                        >
                            <span v-if="loading.fetch">{{ $t('message.fetching') }}</span>
                            <span v-else>{{ $t('message.fetch') }}</span>
                        </v-btn>
                        <v-btn
                            :loading="loading.reset"
                            class="ml-3 flex-grow-1"
                            @click="resetFilters()"
                        >{{ $t('message.reset') }}</v-btn>
<!--                        <ExportTable-->
<!--                            :cols-to-delete="{start_col: 10, ncols: 1}"-->
<!--                            :has-add-function="false"-->
<!--                            file-name="grossmargins"-->
<!--                            table-id="grossMarginsTable"-->
<!--                        ></ExportTable>-->
                        <ExportTableJson
                            :export-conditions="exportConditions"
                            :export-data="GrossMargins"
                            :export-fields="headers"
                            :export-source="'grossmargins'"
                            class="ml-3"
                            style="padding-top: 0px !important; margin-bottom: 0px !important;"
                        />
                    </v-col>
                </div>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.grossMargins"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: itemsPerPageOptions,
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :items="GrossMargins"
            :options.sync="tableOptions"
            :search="searchTerm"
            calculate-widths
            class="mt-0 appic-table-light specification-table"
            dense
            id="grossMarginsTable"
            item-key="Account.id"
            sort-by="GrossMargin.document_date"
            sort-desc
        >
            <template v-slot:item.GrossMargin.source="{item}">
                <span class="text-center" v-if="item.GrossMargin.source == 'invoice'">{{ $t('message.dsSs') }}</span>
                <span class="text-center" v-else-if="item.GrossMargin.source == 'whsales_invoice'">{{ $t('message.wh') }}</span>
                <span class="text-center" v-else>{{ $('message.na') }}</span>
            </template>
            <template v-slot:item.GrossMargin.document_title="{item}">
                <span class="font-weight-bold">{{ item.GrossMargin.document_title }}</span>
            </template>
            <template v-slot:item.GrossMargin.document_date="{item}">
                {{ formatDate(item.GrossMargin.document_date) }}
            </template>
            <template v-slot:item.GrossMargin.payment_term="{item}">
                <template v-if="item.GrossMargin.payment_term != null">
                    <template v-if="item.GrossMargin.payment_term.length < 20">{{ item.GrossMargin.payment_term }}</template>
                    <template v-else>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span
                                    class="truncate"
                                    v-bind="attrs"
                                    v-on="on"
                                >{{ item.GrossMargin.payment_term }}</span>
                            </template>
                            <span>{{ item.GrossMargin.payment_term }}</span>
                        </v-tooltip>
                    </template>
                </template>
            </template>
            <template v-slot:item.GrossMargin.etddate="{item}">
                {{ formatDate(item.GrossMargin.etddate) }}
            </template>
            <template v-slot:item.GrossMargin.etadate="{item}">
                {{ formatDate(item.GrossMargin.etadate) }}
            </template>
            <template v-slot:item.GrossMargin.due="{item}">
                {{ formatDate(item.GrossMargin.due) }}
            </template>
            <template v-slot:item.GrossMargin.received="{item}">
                {{ formatDate(item.GrossMargin.received) }}
            </template>
            <template v-slot:item.GrossMargin.overdue="{item}">
                <span :class="item.GrossMargin.overdue > 30 ? 'red--text' : 'orange--text'" v-if="item.GrossMargin.overdue != null">{{ item.GrossMargin.overdue + " " + $t('message.days') }}</span>
            </template>
            <template v-slot:item.GrossMargin.sellingvalue="{item}">
                <span class="text-no-wrap" v-if="item.GrossMargin.sellingvalue != null">
                    {{ currency(item.GrossMargin.currency_id) + " " + formatThisNumber(item.GrossMargin.sellingvalue, findCurrencyPrecision(item.GrossMargin.currency_id) ) }}
                </span>
            </template>
            <template v-slot:item.GrossMargin.cost="{item}">
                <span class="text-no-wrap" v-if="item.GrossMargin.cost != null">
                    {{ currency(item.GrossMargin.currency_id) + " " + formatThisNumber(item.GrossMargin.cost, findCurrencyPrecision(item.GrossMargin.currency_id) ) }}
                </span>
            </template>
            <template v-slot:item.GrossMargin.grossmargin="{item}">
                <span class="text-no-wrap" v-if="item.GrossMargin.grossmargin != null">
                    {{ currency(item.GrossMargin.currency_id) + " " + formatThisNumber(item.GrossMargin.grossmargin, findCurrencyPrecision(item.GrossMargin.currency_id) ) }}
                </span>
            </template>
            <template v-slot:item.GrossMargin.grossmargin_usd="{item}">
                <span class="text-left text-no-wrap font-weight-bold" v-if="item.GrossMargin.account_id">{{ 'USD ' + formatThisNumber(item.GrossMargin.grossmargin_usd,'0,0.00') }}</span>
                <span class="text-left ml-3" v-else>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                class="warning--text"
                                v-bind="attrs"
                                v-on="on"
                            >mdi mdi-alert-circle</v-icon>
                        </template>
                        <span>{{ $t('message.grossMarginNotGenerated') }}</span>
                    </v-tooltip>
                </span>
            </template>
            <template v-slot:item.GrossMargin.account_id="{ item }">
                <v-menu>
                    <template v-slot:activator="{ on: menu }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('message.moreActions') }}</span>
                        </v-tooltip>
                    </template>
                    <v-list dense>
                        <v-list-item class="font-sm" @click="updateGrossMargin(item.GrossMargin.account_id)" v-if="item.GrossMargin.account_id != null">
                            <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateGrossMargin') }}
                        </v-list-item>
                        <v-list-item class="font-sm" @click="newGrossMargin(item.GrossMargin.source, item.GrossMargin.related_document_id)" v-if="item.GrossMargin.account_id == null && item.GrossMargin.source == 'invoice'">
                            <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.generateGrossMargin') }}
                        </v-list-item>
                        <v-list-item class="font-sm" @click="newGrossMargin(item.GrossMargin.source, item.GrossMargin.related_document_id)" v-if="item.GrossMargin.account_id == null && item.GrossMargin.source == 'whsales_invoice'">
                            <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.generateGrossMargin') }}
                        </v-list-item>
<!--                        <v-list-item class="font-sm" @click="viewPdfBlob(item.GrossMargin.account_id, item.GrossMargin.source)" v-if="item.GrossMargin.account_id != null">-->
<!--                            <v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.grossMargin') }}-->
<!--                        </v-list-item>-->
                        <PrintGrossMarginButton
                            :gross-margin-id="item.GrossMargin.account_id"
                            :source="item.GrossMargin.source"
                            :update-mode="true"
                            :list-mode="true"
                            v-if="item.GrossMargin.account_id != null"
                        />
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import {formatDate, numberFormat} from "Helpers/helpers";
    import {mapActions, mapGetters} from "vuex";
    // import SimpleAlert from "Components/Appic/SimpleAlert";
    import {mapFields} from "vuex-map-fields";
    import { v4 as uuidv4 } from 'uuid';
    // import ExportTableJson from "./ExportTableJson.vue";
    const ExportTable = () => import("Components/Appic/ExportTable");
    const ExportTableJson = () => import("Components/Appic/ExportTableJson");
    const PrintGrossMarginButton = () => import("Components/Appic/Printing/PrintGrossMarginButton");

    export default {
        name: "GrossMarginsListing",
        components: {ExportTableJson, ExportTable, PrintGrossMarginButton},
        data(){
            return {
                dialogs: {
                    error: false,
                    error_message: ""
                },
                defaultFilterOptions: {
                    buyer: null,
                    month: null,
                    office: false,
                    source: null,
                    year: null,
                },
                exportConditions: {},
                filterOptions: {
                    buyer: null,
                    month: null,
                    office: false,
                    source: null,
                    year: null,
                },
                GrossMargins: [],
                hideDefaultFooter: true,
                loading: {
                    fetch: false,
                    filter: {
                        buyers: false,
                        months: false,
                        offices: false,
                        sources: null,
                        years: false,
                    },
                    filterResults: false,
                    grossMargins: false,
                    reset: false
                },
                searchField: null,
                searchTerm: null,
                tableOptions: {
                    page: 1
                },
                totalGrossMargins: 0
            }
        },
        computed: {
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
            ...mapGetters('currency',{
                allCurrencies: 'allCurrencies'
            }),
            ...mapGetters([
                'currencies',
                'months',
                'offices',
                'salesTypes'
            ]),
            headers () {
                return [
                    {
                        id: 0,
                        text: this.$t('message.actions'),
                        value: 'GrossMargin.account_id',
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct'
                    },
                    {
                        id: 1,
                        text: this.$t('message.source'),
                        value: 'GrossMargin.source',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 2,
                        text: this.$t('message.documentNumber'),
                        value: 'GrossMargin.document_title',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 3,
                        text: this.$t('message.documentDate'),
                        value: 'GrossMargin.document_date',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 4,
                        text: this.$t('message.office'),
                        value: 'GrossMargin.office',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 5,
                        text: this.$t('message.contractSo'),
                        value: 'GrossMargin.related_document',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 6,
                        text: this.$t('message.customer'),
                        value: 'GrossMargin.customer',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 7,
                        text: this.$t('message.spl'),
                        value: 'GrossMargin.supplier_code',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 8,
                        text: this.$t('message.agent'),
                        value: 'GrossMargin.main_supplier',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 9,
                        text: this.$t('message.volAndQty'),
                        value: 'GrossMargin.volume',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 10,
                        text: this.$t('message.paymentTerms'),
                        value: 'GrossMargin.payment_term',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 11,
                        text: this.$t('message.etd'),
                        value: 'GrossMargin.etddate',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 12,
                        text: this.$t('message.eta'),
                        value: 'GrossMargin.etadate',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 13,
                        text: this.$t('message.dueDate'),
                        value: 'GrossMargin.due',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 14,
                        text: this.$t('message.received'),
                        value: 'GrossMargin.received',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 15,
                        text: this.$t('message.overDue'),
                        value: 'GrossMargin.overdue',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 16,
                        text: this.$t('message.sellingValue'),
                        value: 'GrossMargin.sellingvalue',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 17,
                        text: this.$t('message.costs'),
                        value: 'GrossMargin.cost',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 18,
                        text: this.$t('message.gm'),
                        value: 'GrossMargin.grossmargin',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 19,
                        text: this.$t('message.gmUsd'),
                        value: 'GrossMargin.grossmargin_usd',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    }
                ]
            },
            itemsPerPageOptions() {
                let options = [10,20,50,-1];
                if(window.screen.height >= 800){
                    options = [15,30,50,-1];
                }
                return options;
            },
            searchFields() {
                return this.headers.filter((header)=>header.searchable === true)
            },
            years() {
                let array = []
                let currentYear = new Date().getFullYear()
                let startYear = currentYear - 5
                while(currentYear > startYear){
                    array.push({Year: {text: currentYear.toString(), id: currentYear}})
                    currentYear = currentYear - 1
                }
                return array
            }
        },
        methods: {
            ...mapActions('currency', {
                getAllCurrencies: 'getAllCurrencies'
            }),
            ...mapActions('grossmargin',{
                getAllGrossMargins: 'getAllGrossMargins'
            }),
            currency(currency_id) {
                return this.allCurrencies.find((currency) => currency.Currency.id == currency_id)?.Currency?.code
            },
            dialogClosed () {
                this.dialogs.error = false;
                this.dialogs.error_message = ''
            },
            filterResults () {
                this.loading.filterResults = true
                let _this = this
                setTimeout(function(){
                    _this.loading.filterResults = false
                },700)
            },
            findCurrencyPrecision ( val ) {
                let currency = this.allCurrencies.find(c => c.Currency.id == val)
                return currency?.Currency?.formatPrecision
            },
            formatDate,
            formatThisNumber(value,format){
                return numberFormat(value,format)
            },
            loadGrossMargins () {
                this.loading.grossMargins = true
                this.loading.fetch = true
                let payload = {
                    filterOptions: this.filterOptions,
                }
                this.getAllGrossMargins( payload )
                    .then((tableData) => {
                        this.GrossMargins = tableData.rows
                        this.totalGrossMargins = tableData.totalRows
                        if(this.GrossMargins.length == 0){
                            //go back to previous year
                            this.filterOptions.year = this.filterOptions.year - 1
                            this.loadGrossMargins()
                        }
                    })
                    .catch(() => {
                        this.loading.grossMargins = false
                        this.loading.fetch = false;
                    })
                    .finally(() => {
                        this.loading.grossMargins = false
                        this.loading.fetch = false
                        this.tableOptions.page = 1
                    })
            },
            newGrossMargin (source, val) {
                let tab = window.open('/v1/gross-margins/add/' + source + '/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            resetFilters () {
                this.loading.reset = true
                this.filterOptions = _.cloneDeep(this.defaultFilterOptions)
                let d = new Date()
                this.filterOptions.year = d.getFullYear()
                this.loadGrossMargins()
                this.loading.reset = false
            },
            updateGrossMargin (val){
                let tab = window.open('/v1/gross-margins/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            viewPdf (accountId, title, source) {
                let document = encodeURIComponent('GM ' + title)
                let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/gross-margins/print/'
                    + source
                    + '/'
                    + accountId
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank");
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            viewPdfBlob (accountId, source) {
                window.open(
                    process.env.VUE_APP_BASE_URL
                    + '/v1/gross-margins/print-v2/'
                    + source
                    + '/'
                    + accountId
                    , "_blank");
            }
        },
        created() {
            if(this.allCurrencies.length == 0) this.getAllCurrencies()
            let d = new Date()
            this.filterOptions.year = d.getFullYear()
            this.loadGrossMargins();
        }
    }
</script>

<style>
    .border-top-1 {
        border-top: 1px solid lightgray !important;
    }
    .col-0-75 {
        flex: 0 0 6.25%;
        max-width: 6.25%;
    }
    .col-1-5 {
        flex: 0 0 12.5%;
        max-width: 12.5%;
    }
    .col-3-5 {
        flex: 0 0 29.16666667%;
        max-width: 29.16666667%;
    }
    .v-dialog {
        min-width: 200px !important;
    }
    .v-data-table td {
        padding: 0 3px;
        font-size: 0.8rem !important;
        line-height: 0.8rem;
    }
    .text-start {
        padding-left: 3px !important;
        padding-top: 3px !important;
        padding-right: 3px !important;
        vertical-align: middle !important;
    }
    .font-xs {
        font-size: 0.60rem;
    }
    .mt-5px {
        margin-top: 5px !important;
    }
    .mt-6px {
        margin-top: 6px !important;
    }
    .offset-2-5 {
        margin-left: 20.8333333%;
    }
    .truncate {
        display: inline-block;
        width: 100px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .v-data-table-header th {
        white-space: nowrap;
    }
</style>